// Vuetify
import "../scss/_variables.scss";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { aliases, fa } from 'vuetify/iconsets/fa';

const acePolishing = {
    dark: false,
    colors: {
        primary: "#013473",
        primaryshade: "#336AB0",
        text: "#1B1E23",
        textdim: "#505154",
        background: "#EDF2F8",
        secondary: "#256A67",
        secondaryshade: "#43908C",
        accent: "#82B1FF",
        error: "#B00020",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00",
        lightblue: "#14c6FF",
        yellow: "#FFCF00",
        pink: "#FF1976",
        orange: "#FF8657",
        magenta: "#C33AFC",
        darkblue: "#1E2D56",
        grey: "#505154",
        neutralgrey: "#9BA6C1",
        green: "#2ED47A",
        red: "#FF5c4E",
        darkblueshade: "#308DC2",
        lightgray: "#BDBDBD",
        lightpink: "#FFCFE3",
        white: "#FFFFFF",
        muted: "#6c757d",
        bannergrey: "#585454",
    },
    variables: {
        'medium-emphasis-opacity': 1,
    },
};

const vuetify = createVuetify({
    components,
    directives,
    icons: {
        defaultSet: 'fa',
        aliases,
        sets: {
            fa,
        },
    },
    theme: {
        defaultTheme: "acePolishing",
        themes: {
            acePolishing,
        },
    },
});

export default vuetify;